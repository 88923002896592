import { trpc } from '@/utils/trpc';
import { IconChevronLeft } from '@tabler/icons-react';
import { createFileRoute, useParams, useRouter } from '@tanstack/react-router';
import AvatarPic from '@/assets/images/avatar.png';
import { ChatMessageList } from '@/components/chat/chat-message-list';
import { ChatBubble, ChatBubbleMessage } from '@/components/chat/chat-bubble';
import { useChatMessages } from '@/hooks/useChatStore';
import { ActionBubble } from '@/components/ChatWindow/ActionBubble';

export const Route = createFileRoute('/session-summary/$id')({
  component: () => <SessionSummary />,
});

const splitContentIntoSentences = (content: string): string[] => {
  try {
    // Regex Explanation:
    // ([?。]) - Capture either ? or 。
    const regex = /([?。])/g;

    // Split the content while capturing the delimiters
    const parts = content.split(regex);

    // Reconstruct sentences by combining text with its delimiter
    const sentences: string[] = [];
    for (let i = 0; i < parts.length - 1; i += 2) {
      const sentence = parts[i].trim() + parts[i + 1];
      sentences.push(sentence);
    }

    // Handle any remaining part without a delimiter
    if (parts.length % 2 !== 0 && parts[parts.length - 1].trim() !== '') {
      sentences.push(parts[parts.length - 1].trim());
    }

    return sentences.length > 0 ? sentences : [content];
  } catch (e) {
    return [content];
  }
};

function SessionSummary() {
  const { id } = useParams({
    from: '/session-summary/$id',
  });

  const { data } = trpc.session.getSession.useQuery({
    sessionId: +id,
  });

  const router = useRouter();
  const { history } = useChatMessages(+id);

  if (!data) {
    return null;
  }
  const moodTags = data.session_summary?.mood_tags as string[];

  if (!moodTags || moodTags.length === 0) {
    return null;
  }

  return (
    <div className="flex w-full flex-col p-4">
      <header className="flex items-center px-2 py-4">
        <div className="relative flex flex-1 items-center">
          <button
            className="l-2 absolute"
            onClick={() => {
              router.history.back();
            }}
          >
            <IconChevronLeft className="h-6 w-6" />
          </button>
          <h1 className="flex-1 text-center text-lg font-medium">聊愈手记</h1>
        </div>
      </header>

      <div className="text-muted-foreground px-4 py-2 text-center text-sm">AI 生成内容仅供参考</div>

      <div className="w-full rounded-md bg-[#FFCDB6] p-2">
        <div className="flex size-full flex-col items-center justify-center rounded-md bg-white/80 p-4">
          <div className="relative my-6">
            <span className="relative z-10 text-2xl font-semibold">情绪洞察卡</span>
            <div className="absolute inset-x-0 bottom-0 h-2 bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>
          </div>

          <div className="relative flex h-56 w-full items-center justify-center">
            <img src={AvatarPic} alt="insight card" className="size-16" />
            {moodTags[0] && (
              <div className="absolute left-1/2 top-12 translate-x-12 rounded-lg border-2 border-solid border-[#F6D3EE] bg-white p-4 text-sm font-light">
                {moodTags[0]}
              </div>
            )}
            {moodTags[1] && (
              <div className="absolute left-1/2 top-40 -translate-x-28 rounded-lg border-2 border-solid border-[#F5D2C5] bg-white p-4 text-sm font-light">
                {moodTags[1]}
              </div>
            )}
            {moodTags[2] && (
              <div className="absolute left-1/2 top-8 -translate-x-32 rounded-lg border-2 border-solid border-[#F4DFC7] bg-white p-4 text-sm font-light">
                {moodTags[2]}
              </div>
            )}
          </div>

          <div className="flex w-full flex-col gap-4">
            <div className="flex w-full flex-col gap-6 rounded-2xl bg-white p-4">
              <div className="flex w-fit items-center rounded-full bg-gradient-to-r from-[#FECE97] to-[#EFEADF]/10 bg-clip-border">
                <div className="py-1 pl-4 pr-8">总结</div>
              </div>
              <div className="text-sm font-light">{data.session_summary?.summary}</div>
            </div>

            <div className="flex w-full flex-col gap-6 rounded-2xl bg-white p-4">
              <div className="flex w-fit items-center rounded-full bg-gradient-to-r from-[#FFC5AB] to-[#FDC6F200]/10 bg-clip-border">
                <div className="py-1 pl-4 pr-8">潜意识分析</div>
              </div>
              <div className="text-sm font-light">{data.session_summary?.subconscious}</div>
            </div>

            <div className="flex w-full flex-col gap-6 rounded-2xl bg-white p-4">
              <div className="flex w-fit items-center rounded-full bg-gradient-to-r from-[#F9A6E9] to-[#FED7C4]/10 bg-clip-border">
                <div className="py-1 pl-4 pr-8">思考...</div>
              </div>
              <div className="text-sm font-light">{data.session_summary?.thought}</div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative my-12 h-[400px] w-full rounded-xl bg-gradient-to-tl from-[#FDC6F2] to-[#FED7C4]">
        <div className="absolute inset-1 flex items-center justify-center rounded-md bg-white/80 backdrop-blur-sm">
          <ChatMessageList className="flex w-full flex-col gap-2 px-4">
            <div className="flex h-full w-full flex-col items-center gap-2 py-12">
              {history.map((message) => {
                let content = '';
                if (message.content.type === 'plain_text') {
                  content = message.content.content;
                }
                if (message.content.type === 'choice') {
                  content = message.content.content.question;
                }

                if (message.role === 'ai') {
                  const segments = splitContentIntoSentences(content);
                  const reaction = message.reaction;
                  return segments.map((segment, segmentIndex) => {
                    return (
                      <ActionBubble
                        key={`${message.id}-${segmentIndex}`}
                        message={segment}
                        messageId={message.id}
                        segmentIndex={segmentIndex}
                        initialReaction={reaction?.segment_index === segmentIndex ? reaction.type : undefined}
                      />
                    );
                  });
                } else {
                  return (
                    <ChatBubble key={message.id} variant={'sent'}>
                      <ChatBubbleMessage variant={'sent'}>{content}</ChatBubbleMessage>
                    </ChatBubble>
                  );
                }
              })}
            </div>
          </ChatMessageList>
        </div>
      </div>
    </div>
  );
}
