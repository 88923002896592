import { AnimatedGradientText } from './AnimatedGradientText';
import { Emojis } from '@/utils/emoji';

type Props = {
  choiceOptions: string[];
  optionType: 'emoji' | 'text' | 'score';
  onSelected: (selectedOption: string) => void;
};

function ScoreList({ choiceOptions, onSelected }: Omit<Props, 'optionType'>) {
  return (
    <div className="grid w-full grid-cols-5 gap-x-8 gap-y-4 px-8 py-12">
      {choiceOptions.map((option) => (
        <button key={option} onClick={() => onSelected(option)} className="flex items-center justify-center">
          <AnimatedGradientText className="flex aspect-square h-12 w-12 flex-col items-center justify-center rounded-full">
            {option}
          </AnimatedGradientText>
        </button>
      ))}
    </div>
  );
}

function EmojiChoiceList({ choiceOptions, onSelected }: Omit<Props, 'optionType'>) {
  return (
    <div className="grid w-full grid-cols-5 gap-x-6 gap-y-4 px-4 py-12 lg:gap-x-8 lg:px-8">
      {choiceOptions.map((option) => (
        <button
          key={option}
          onClick={() => onSelected(option)}
          className="flex flex-col items-center justify-center gap-3 rounded-xl bg-white/80 py-2"
        >
          {/* @ts-expect-error no typing now */}
          {Emojis[option]}
          <span className="text-foreground/60">{option}</span>
        </button>
      ))}
    </div>
  );
}

function TextOptionList({ choiceOptions, onSelected }: Omit<Props, 'optionType'>) {
  return (
    <div className="flex-center flex w-full flex-col gap-4 px-6 py-4">
      {choiceOptions.map((option) => (
        <button key={option} onClick={() => onSelected(option)}>
          <AnimatedGradientText className="h-12">
            <span>{option}</span>
          </AnimatedGradientText>
        </button>
      ))}
    </div>
  );
}

export function ChoiceInput({ choiceOptions, optionType, onSelected }: Props) {
  switch (optionType) {
    case 'emoji':
      return <EmojiChoiceList choiceOptions={choiceOptions} onSelected={onSelected} />;
    case 'score':
      return <ScoreList choiceOptions={choiceOptions} onSelected={onSelected} />;
    case 'text':
      return <TextOptionList choiceOptions={choiceOptions} onSelected={onSelected} />;
    default:
      return null;
  }
}
