import { useAuth } from '@/hooks/useAuth';
import { trpc } from '@/utils/trpc';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { tz } from '@date-fns/tz';
import { endOfWeek, eachDayOfInterval, startOfWeek, format, isSameDay } from 'date-fns';
import { useMemo } from 'react';
import { ScrollArea } from '@/components/ui/scroll-area';
import { CalendarIcon, MoonIcon, TrashIcon } from '@radix-ui/react-icons';
import { Emojis } from '@/utils/emoji';
import { cn } from '@/lib/utils';
import Duration from '@/assets/icons/duration.svg';
import { zhCN } from 'date-fns/locale';
import { AnimatePresence, motion } from 'framer-motion';

export const Route = createFileRoute('/diary')({
  component: () => <DiaryPage />,
});

function DiaryPage() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const { start, end, interval } = useMemo(() => {
    const start = format(
      startOfWeek(new Date(), {
        in: tz('Asia/Shanghai'),
      }),
      'yyyy-MM-dd'
    );
    const end = format(
      endOfWeek(new Date(), {
        in: tz('Asia/Shanghai'),
      }),
      'yyyy-MM-dd'
    );

    const interval = eachDayOfInterval({
      start,
      end,
    }).map((date) => format(date, 'yyyy-MM-dd'));

    return {
      start,
      end,
      interval,
    };
  }, []);

  const { data: dailyMoods } = trpc.dailyMood.listDailyMode.useQuery({
    start,
    end,
  });

  const utils = trpc.useUtils();
  const { data: sessions } = trpc.session.listSessions.useQuery();

  const { mutateAsync: deleteAsync } = trpc.session.deleteSession.useMutation({
    onMutate(variables) {
      utils.session.listSessions.setData(undefined, (prev) => {
        return prev?.filter((item) => item.id !== variables.sessionId);
      });
    },
  });

  if (!user) {
    navigate({
      to: '/login',
    });
  }

  const weekStr = ['日', '一', '二', '三', '四', '五', '六'];

  return (
    <div className="flex h-dvh w-screen flex-col gap-4 overflow-hidden text-[#2E2D29]">
      <div className="m-4 flex flex-col gap-6 rounded-xl bg-[#EEEADF] px-4 py-4">
        <div className="flex w-full items-center justify-between">
          <div className="relative text-xl">
            <span className="relative z-20 font-bold">本周情绪</span>
            <div className="absolute inset-x-0 bottom-0 z-10 h-2 bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>
          </div>
          <button
            className="flex items-center justify-between gap-2 rounded-full bg-[#F8F5F0] px-2 py-1 text-sm text-[#5F5F5F]"
            onClick={() => {
              navigate({
                to: '/calendar',
              });
            }}
          >
            <CalendarIcon className="size-4" />
            <span>
              {start.split('-').slice(1).join('.')} - {end.split('-').slice(1).join('.')}
            </span>
          </button>
        </div>
        <div className="flex w-full flex-nowrap items-center justify-evenly gap-2">
          {interval.map((date, index) => {
            let mood: any = '';
            if (dailyMoods) {
              const item = dailyMoods.find((item) => item.date === date);
              mood = item?.mood ?? '';
            }

            const isToday = isSameDay(new Date(date), new Date(), {
              in: tz('Asia/Shanghai'),
            });

            const hasMood = !!mood;
            return (
              <div
                key={date}
                className={cn(
                  'flex max-w-20 flex-1 flex-col items-center gap-4 rounded-full bg-[#FFFFFF] px-1 py-2 text-xs text-[#9D9D9D]',
                  isToday && 'bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]'
                )}
              >
                <span className="text-sm">{date.split('-').at(-1)}</span>
                <span className={cn('flex size-6 items-center justify-center', hasMood && 'scale-75')}>
                  {Emojis[mood as keyof typeof Emojis] ?? weekStr[index]}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex-1 overflow-auto">
        <ScrollArea className="size-full">
          {sessions && (
            <div className="flex w-full flex-col gap-4 p-4">
              <AnimatePresence>
                {sessions.map((session) => {
                  return (
                    <motion.div layout="position" key={session.id} exit={{ opacity: 0, left: 10 }} className="flex w-full flex-col gap-4">
                      <div className="py-2 text-[#2E2D29]">
                        {format(session.lastActiveTime, "MM'月'dd'日' EEE", {
                          locale: zhCN,
                          in: tz('Asia/Shanghai'),
                        })}
                      </div>
                      <div className="flex w-full items-center justify-between text-xs text-[#5F5F5F]">
                        <div className="flex items-center gap-4">
                          <span className="flex items-center gap-1">
                            <MoonIcon />
                            <span>
                              {format(session.lastActiveTime, 'HH:mm a', {
                                locale: zhCN,
                                in: tz('Asia/Shanghai'),
                              })}
                            </span>
                          </span>
                          <span className="flex items-center gap-1">
                            <Duration className="size-4" />
                            <span>{session.duration}</span>
                          </span>
                        </div>

                        <button
                          className="flex items-center gap-1"
                          onClick={() => {
                            deleteAsync({
                              sessionId: session.id,
                            });
                          }}
                        >
                          <TrashIcon />
                          <span className="text-[#9D9D9D]">删除</span>
                        </button>
                      </div>
                      <button
                        className="w-full rounded-xl bg-[#F5E7D7] p-4 text-xs leading-relaxed text-[#2E2D29]"
                        onClick={() => {
                          navigate({
                            to: '/session-summary/$id',
                            params: {
                              id: session.id + '',
                            },
                          });
                        }}
                      >
                        <p className="size-full text-left">{session.summary}</p>
                      </button>
                    </motion.div>
                  );
                })}
              </AnimatePresence>
            </div>
          )}
        </ScrollArea>
      </div>
    </div>
  );
}
