import SmilingFace from '@/assets/emoijs/smiling_face.svg';
import StarStruck from '@/assets/emoijs/star_struck.svg';
import SurpriseFace from '@/assets/emoijs/surprise_face.svg';
import WorryFace from '@/assets/emoijs/worry_face.svg';
import SadFace from '@/assets/emoijs/sad_face.svg';
import GuiltyFace from '@/assets/emoijs/guilty_face.svg';
import FearFace from '@/assets/emoijs/fear_face.svg';
import AngryFace from '@/assets/emoijs/angry_face.svg';
import SalutingFace from '@/assets/emoijs/saluting_face.svg';
import AnxiousFace from '@/assets/emoijs/anxious_face.svg';

export const Emojis = {
  快乐: <SalutingFace className="size-8 lg:size-12" />,
  感动: <SmilingFace className="size-8 lg:size-12" />,
  满足: <StarStruck className="size-8 lg:size-12" />,
  惊讶: <SurpriseFace className="size-8 lg:size-12" />,
  担忧: <WorryFace className="size-8 lg:size-12" />,
  伤心: <SadFace className="size-8 lg:size-12" />,
  内疚: <GuiltyFace className="size-8 lg:size-12" />,
  焦虑: <AnxiousFace className="size-8 lg:size-12" />,
  恐惧: <FearFace className="size-8 lg:size-12" />,
  生气: <AngryFace className="size-8 lg:size-12" />,
};
