import { useEffect, useRef } from 'react';
import { IconChevronLeft } from '@tabler/icons-react';
import { ChatMessageList } from '@/components/chat/chat-message-list';
import { ChatBubble, ChatBubbleMessage } from '@/components/chat/chat-bubble';
import { useChatInput, useChatMessages, useChatSession } from '@/hooks/useChatStore';
import Logo from '@/assets/logo/logo-text.svg';
import { useNavigate } from '@tanstack/react-router';
import VoiceMessage from '@/assets/icons/voice-message.svg';
import { VoiceInput } from './VoiceInput';
import { TextInput } from './TextInput';
import { FluentEmoji } from '@lobehub/fluent-emoji';
import { AnimatedGradientText } from './AnimatedGradientText';
import { ActionBubble } from './ActionBubble';

const splitContentIntoSentences = (content: string): string[] => {
  try {
    // Regex Explanation:
    // ([?。]) - Capture either ? or 。
    const regex = /([?。])/g;

    // Split the content while capturing the delimiters
    const parts = content.split(regex);

    // Reconstruct sentences by combining text with its delimiter
    const sentences: string[] = [];
    for (let i = 0; i < parts.length - 1; i += 2) {
      const sentence = parts[i].trim() + parts[i + 1];
      sentences.push(sentence);
    }

    // Handle any remaining part without a delimiter
    if (parts.length % 2 !== 0 && parts[parts.length - 1].trim() !== '') {
      sentences.push(parts[parts.length - 1].trim());
    }

    return sentences.length > 0 ? sentences : [content];
  } catch (e) {
    return [content];
  }
};

const ChatWindowHeader = () => {
  const navigateTo = useNavigate();
  return (
    <div className="relative flex w-full items-center justify-center py-4 lg:py-8">
      <button
        className="absolute left-2 top-1/2 -translate-y-1/2"
        onClick={() =>
          navigateTo({
            to: '/',
          })
        }
      >
        <IconChevronLeft className="text-primary" />
      </button>

      <button>
        <Logo
          className="h-4 w-auto"
          onClick={() =>
            navigateTo({
              to: '/',
            })
          }
        />
      </button>
    </div>
  );
};

const ChatWindowBg = () => (
  <>
    <div className="absolute inset-0 -z-10 bg-[radial-gradient(circle_400px_at_-20%_30px,#d5c5ff,transparent)]" />
    <div className="absolute inset-0 -z-10 translate-x-0 bg-[radial-gradient(circle_400px_at_30%_200px,#FFE6F5,transparent)]" />
    <div className="absolute inset-0 -z-10 bg-[radial-gradient(circle_400px_at_100%_20px,#FFF1E8,transparent)]" />
  </>
);

export const ChatWindow = () => {
  const { currentSession, isLoadingPreviousSession, hasPreviousSession, continuePreviousSession, createNewSession } = useChatSession();
  const { history, isWaitingForResponse } = useChatMessages(currentSession);
  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ref.current?.scrollIntoView({
      block: 'end',
      inline: 'nearest',
      behavior: 'smooth',
    });
  }, [history, isWaitingForResponse]);

  const { inputType } = useChatInput();

  if (isLoadingPreviousSession) {
    return null;
  }

  return (
    <div className="relative flex h-dvh w-screen items-center justify-center">
      <div className="flex size-full flex-col justify-center overflow-auto">
        <ChatWindowHeader />
        <div className="relative flex w-full flex-grow flex-col overflow-auto">
          {!currentSession && hasPreviousSession && (
            <div className="absolute inset-0 z-50 flex size-full flex-col items-center justify-center bg-white/20 backdrop-blur-sm">
              <div className="flex w-full flex-col items-center justify-center gap-8 px-12 md:px-20">
                <button className="w-full" onClick={() => continuePreviousSession()}>
                  <AnimatedGradientText className="h-24 w-full text-base">
                    <div className="flex items-center gap-6">
                      <FluentEmoji cdn="aliyun" emoji="😀" type="anim" className="size-8 lg:size-12" />
                      <span>继续上次对话</span>
                    </div>
                  </AnimatedGradientText>
                </button>
                <button className="w-full" onClick={() => createNewSession()}>
                  <AnimatedGradientText className="h-24 w-full text-base">
                    <div className="flex items-center gap-6">
                      <FluentEmoji cdn="aliyun" emoji="👋" type="anim" className="size-8 lg:size-12" />
                      <span>开始新的对话</span>
                    </div>
                  </AnimatedGradientText>
                </button>

                <button
                  className="w-full"
                  onClick={() => {
                    navigate({
                      to: '/diary',
                    });
                  }}
                >
                  <AnimatedGradientText className="h-24 w-full text-base">
                    <div className="flex items-center gap-6">
                      <FluentEmoji cdn="aliyun" emoji="💌" type="anim" className="size-8 lg:size-12" />
                      <span>心情日记</span>
                    </div>
                  </AnimatedGradientText>
                </button>
              </div>
            </div>
          )}
          <div className="relative flex w-full flex-grow overflow-hidden py-4">
            <ChatMessageList className="flex w-full flex-col gap-2 px-4">
              <div className="flex h-full w-full flex-col gap-3" ref={ref}>
                {history.map((message) => {
                  let content = '';
                  if (message.content.type === 'plain_text') {
                    content = message.content.content;
                  }
                  if (message.content.type === 'choice') {
                    content = message.content.content.question;
                  }

                  if (message.content.type === 'voice') {
                    return (
                      <ChatBubble variant={'sent'} key={message.id}>
                        <ChatBubbleMessage variant={'sent'}>
                          {
                            <button className="flex items-center gap-2 pl-12">
                              <span>{message.content.duration}</span>
                              <VoiceMessage className="text-foreground/60 size-4" />
                            </button>
                          }
                        </ChatBubbleMessage>
                      </ChatBubble>
                    );
                  }

                  if (message.role === 'ai') {
                    const segments = splitContentIntoSentences(content);
                    const reaction = message.reaction;
                    return segments.map((segment, segmentIndex) => {
                      return (
                        <ActionBubble
                          key={`${message.id}-${segmentIndex}`}
                          message={segment}
                          messageId={message.id}
                          segmentIndex={segmentIndex}
                          initialReaction={reaction?.segment_index === segmentIndex ? reaction.type : undefined}
                        />
                      );
                    });
                  } else {
                    return (
                      <ChatBubble key={message.id} variant={'sent'}>
                        <ChatBubbleMessage variant={'sent'}>{content}</ChatBubbleMessage>
                      </ChatBubble>
                    );
                  }
                })}
                {isWaitingForResponse && (
                  <ChatBubble key="placeholder" variant="received">
                    <ChatBubbleMessage variant={'received'} isLoading />
                  </ChatBubble>
                )}
              </div>
            </ChatMessageList>
          </div>
          {inputType === 'text' ? <TextInput /> : <VoiceInput />}
        </div>
      </div>
      <ChatWindowBg />
    </div>
  );
};
