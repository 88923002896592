import { create } from 'zustand';

import { trpc } from '@/utils/trpc';
import { useCallback, useRef, useEffect } from 'react';
import { blobToBase64 } from '@/lib/utils';
import { skipToken, useIsMutating } from '@tanstack/react-query';
import { toast } from 'sonner';
import { getMutationKey } from '@trpc/react-query';

type Store = {
  inputType: 'text' | 'voice';
  message: string;
  currentSession: number | undefined;
  setMessage: (message: string) => void;
  setInputType: (inputType: 'text' | 'voice') => void;
  setCurrentSession: (sessionId: number) => void;
};

export const useChatInput = create<Store>((set) => ({
  currentSession: undefined,
  isRecording: false,
  inputType: 'text',
  message: '',
  setMessage: (message) => set({ message }),
  setInputType: (inputType) => set({ inputType }),
  setCurrentSession: (currentSession) => set({ currentSession }),
}));

export function useChatSession() {
  const { currentSession, setCurrentSession } = useChatInput();
  const { data: previousSession, isLoading: isLoadingPreviousSession } = trpc.session.getLatestSession.useQuery();
  const { isPending: isGenerationNewSession, mutateAsync: addNewSession } = trpc.session.addNewSession.useMutation();
  const { mutateAsync: continueLastSession } = trpc.session.continueLastSession.useMutation();
  const createNewSession = useCallback(async () => {
    const { sessionId } = await addNewSession();
    setCurrentSession(sessionId);
  }, [addNewSession, setCurrentSession]);

  const continuePreviousSession = useCallback(async () => {
    await continueLastSession({
      sessionId: previousSession!,
    });
    setCurrentSession(previousSession!);
  }, [continueLastSession, previousSession, setCurrentSession]);

  useEffect(() => {
    if (!isLoadingPreviousSession && !previousSession) {
      // if we are sure that we do not have previous session we create new session automatically
      createNewSession();
    }
  }, [createNewSession, isLoadingPreviousSession, previousSession]);

  return {
    hasPreviousSession: !!previousSession,
    isLoadingPreviousSession,
    isGenerationNewSession,
    createNewSession,
    currentSession,
    continuePreviousSession,
  };
}

export function useChatMessages(sessionId: number | undefined) {
  const utils = trpc.useUtils();
  const { data: history, isLoading } = trpc.chat.getMessageHistory.useQuery(
    sessionId
      ? {
          sessionId,
        }
      : skipToken
  );

  const { mutateAsync, error } = trpc.chat.addMessage.useMutation();
  const { mutateAsync: updateDailyMood } = trpc.dailyMood.updateDailyMood.useMutation();
  const mutationKey = getMutationKey(trpc.chat.addMessage);

  const isWaitingForResponse =
    useIsMutating({
      mutationKey,
    }) > 0;

  const bufferRef = useRef<string[]>([]);

  useEffect(() => {
    if (error) {
      toast.error('发送消息失败, 请稍后再试或开启新对话');
    }
  }, [error]);

  const flushMessage = useCallback(async () => {
    if (bufferRef.current.length === 0) return;
    const buffer = bufferRef.current;
    bufferRef.current = [];
    const {
      id: messageId,
      content,
      meta,
    } = await mutateAsync({
      message: buffer.join(' '),
      sessionId: sessionId!,
      messageType: 'text',
    });
    utils.chat.getMessageHistory.setData(
      {
        sessionId: sessionId!,
      },
      (prev) => [
        ...(prev || []),
        {
          id: messageId,
          content,
          meta,
          role: 'ai',
          reaction: null,
        },
      ]
    );
  }, [mutateAsync, sessionId, utils]);

  const sendMessage = useCallback(
    (message: string) => {
      bufferRef.current.push(message);
      utils.chat.getMessageHistory.setData(
        {
          sessionId: sessionId!,
        },
        (prev) => [
          ...(prev || []),
          {
            id: new Date().getTime(),
            content: {
              type: 'plain_text',
              content: message,
            },
            role: 'human',
            meta: undefined,
            reaction: null,
          },
        ]
      );
    },
    [sessionId, utils.chat.getMessageHistory]
  );

  const respondToChoice = useCallback(
    (option: string, currentQuestionId: string) => {
      if (currentQuestionId === '1') {
        updateDailyMood({
          mood: option,
        });
      }
      sendMessage(option);
      flushMessage();
    },
    [flushMessage, sendMessage, updateDailyMood]
  );

  const sendVoice = useCallback(
    async (voice: Blob, duration: number) => {
      const encodedVoice = await blobToBase64(voice);
      utils.chat.getMessageHistory.setData(
        {
          sessionId: sessionId!,
        },
        (prev) => [
          ...(prev || []),
          {
            id: new Date().getTime(),
            content: {
              type: 'voice',
              content: encodedVoice,
              duration,
            },
            role: 'human',
            meta: undefined,
            reaction: null,
          },
        ]
      );

      const {
        id: messageId,
        content,
        meta,
      } = await mutateAsync({
        message: encodedVoice,
        sessionId: sessionId!,
        messageType: 'voice',
      });
      utils.chat.getMessageHistory.setData(
        {
          sessionId: sessionId!,
        },
        (prev) => [
          ...(prev || []),
          {
            id: messageId,
            content,
            meta,
            role: 'ai',
            reaction: null,
          },
        ]
      );
    },
    [mutateAsync, sessionId, utils.chat.getMessageHistory]
  );

  return {
    sendMessage,
    history: history || [],
    isWaitingForResponse,
    flushMessage,
    isLoadingHistory: isLoading,
    respondToChoice,
    sendVoice,
  };
}
