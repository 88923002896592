import { AnimatedGradientText } from '@/components/ChatWindow/AnimatedGradientText';
import { Calendar } from '@/components/ui/calendar';
import { Emojis } from '@/utils/emoji';
import { trpc } from '@/utils/trpc';
import { tz } from '@date-fns/tz';
import { Cross1Icon } from '@radix-ui/react-icons';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { endOfMonth, format, startOfMonth } from 'date-fns';
import { zhCN } from 'date-fns/locale';
import { useState } from 'react';
import emotionPlaceholder from '@/assets/images/emotion-placeholder.png';

export const Route = createFileRoute('/calendar')({
  component: () => <CalendarPage />,
});

function CalendarPage() {
  const navigate = useNavigate();
  const [month, setMonth] = useState(
    format(new Date(), 'yyyy-MM-dd', {
      in: tz('Asia/Shanghai'),
    })
  );

  const start = startOfMonth(new Date(month));

  const end = endOfMonth(new Date(month));

  const { data: dailyMoods } = trpc.dailyMood.listDailyMode.useQuery({
    start: format(start, 'yyyy-MM-dd'),
    end: format(end, 'yyyy-MM-dd'),
  });

  return (
    <div className="relative flex h-dvh w-screen flex-col gap-2">
      <button
        className="absolute left-4 top-4"
        onClick={() =>
          navigate({
            to: '/diary',
          })
        }
      >
        <Cross1Icon className="text-primary size-4 text-gray-500" />
      </button>

      <Calendar
        mode="default"
        className="w-full text-[#5F5F5F]"
        locale={zhCN}
        weekStartsOn={0}
        month={new Date(month)}
        onMonthChange={(date) => {
          setMonth(format(date, 'yyyy-MM-dd'));
        }}
        components={{
          Day: ({ date }) => {
            const dateStr = format(date, 'yyyy-MM-dd');
            const mood = dailyMoods?.find(({ date }) => date === dateStr);
            if (mood) {
              return (
                <div className="flex h-8 w-8 items-center justify-center rounded-full">
                  {/* @ts-ignore */}
                  {Emojis[mood.mood]}
                </div>
              );
            }
            return <div className="flex h-8 w-8 items-center justify-center rounded-full">{date.getDate()}</div>;
          },
        }}
        classNames={{
          caption: 'flex justify-center pt-6  mx-16 relative items-center',
        }}
      />

      <div className="w-full p-4">
        <AnimatedGradientText className="w-full rounded-md bg-white py-4">
          <div className="flex w-full flex-col items-center justify-center">
            <div className="relative">
              <span className="relative z-20 font-bold">本周情绪</span>
              <div className="absolute inset-x-0 bottom-0 z-10 h-1 bg-gradient-to-r from-[#FDC6F2] to-[#FED7C4]"></div>
            </div>

            <img src={emotionPlaceholder} alt="" className="w-2/3 bg-cover" />

            <div className="text-sm font-normal text-[#9D9D9D]">聊愈后就能看到本月情绪分析啦</div>
          </div>
        </AnimatedGradientText>
      </div>
    </div>
  );
}
