import { IconSend, IconVolume } from '@tabler/icons-react';
import { Input } from '../ui/input';
import { useChatMessages, useChatInput, useChatSession } from '@/hooks/useChatStore';
import { toast } from 'sonner';
import { useDebouncedCallback } from 'use-debounce';
import { motion } from 'framer-motion';
import { useMemo } from 'react';
import { ChoiceInput } from './ChoiceInput';

export function TextInput() {
  const { currentSession } = useChatSession();
  const { sendMessage, isWaitingForResponse, flushMessage, history, respondToChoice } = useChatMessages(currentSession);
  const { message, setMessage, setInputType } = useChatInput();

  const finishTyping = useDebouncedCallback(() => {
    flushMessage();
  }, 3000);

  const { responseType, choiceOptions, optionType, isWaitingForNextQuestion, currentQuestionId } = useMemo(() => {
    let responseType = 'plain_text';
    let choiceOptions: string[] = [];
    let optionType: 'emoji' | 'text' | 'score' = 'text';
    let isWaitingForNextQuestion = false;
    let currentQuestionId = '';
    if (history.at(-1)?.role === 'ai') {
      const lastAiMessage = history.at(-1);
      if (lastAiMessage && lastAiMessage?.content.type === 'choice') {
        currentQuestionId = lastAiMessage.meta?.sceneNodeId ?? '';
        responseType = 'choice';
        choiceOptions = lastAiMessage.content.content.options;
        // @ts-expect-error void
        optionType = lastAiMessage.content.content.optionType;
      }
    } else {
      const lastAiMessage = [...history].reverse().find((message) => message.role === 'ai');
      if (lastAiMessage && lastAiMessage?.content.type === 'choice' && isWaitingForResponse) {
        isWaitingForNextQuestion = true;
      }
    }
    return {
      responseType,
      choiceOptions,
      optionType,
      currentQuestionId,
      isWaitingForNextQuestion,
    };
  }, [history, isWaitingForResponse]);

  return (
    !isWaitingForNextQuestion &&
    (responseType === 'choice' ? (
      <ChoiceInput
        optionType={optionType}
        choiceOptions={choiceOptions}
        onSelected={(option) => {
          respondToChoice(option, currentQuestionId);
        }}
      />
    ) : (
      <motion.div layout className="bg-chat-dock relative flex shrink-0 select-none flex-col items-center rounded-t-2xl pb-8">
        <form className="w-full">
          <div className="flex w-full items-center justify-between gap-4 px-4 pt-3">
            <button
              className="shrink-0"
              type="button"
              onClick={async () => {
                try {
                  await navigator.mediaDevices.getUserMedia({ audio: true });
                  setInputType('voice');
                } catch (e) {
                  toast.error('请允许麦克风权限');
                }
              }}
            >
              <IconVolume className="text-foreground/60" />
            </button>
            <Input
              className="flex-grow border-0 bg-white ring-0 focus-visible:ring-0"
              value={message}
              enterKeyHint="send"
              onChange={(e) => setMessage(e.target.value)}
              onKeyUp={() => {
                finishTyping();
              }}
              onKeyDown={(e) => {
                if (!message) {
                  return;
                }
                if (e.key === 'Enter') {
                  if (!isWaitingForResponse) {
                    sendMessage(message);
                    setMessage('');
                  }
                  e.preventDefault();
                }
              }}
            />
            <button
              className="shrink-0"
              type="button"
              disabled={!message || isWaitingForResponse}
              onClick={() => {
                sendMessage(message);
                setMessage('');
              }}
            >
              <IconSend className="text-foreground/60" />
            </button>
          </div>
        </form>
      </motion.div>
    ))
  );
}
